import Button, { BUTTON_VARIANT, ActionButton } from '@bugbug/core/components/Button';
import { inc, dec, cond, equals } from 'ramda';
import { useRef, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import StripeElements from '~/components/StripeElements';
import useQueryString from '~/hooks/useQueryString';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import BillingAddressForm from '../BillingAddressForm';
import PaymentForm from '../PaymentForm';
import PaymentStatus from '../PaymentStatus';
import PlanSummary from '../PlanSummary';

import { CHECKOUT_STEPS_LIST, CHECKOUT_STEP } from './CheckoutWizard.constants';
import {
  Container,
  Description,
  Navigation,
  Content,
  Stepper,
  Step,
  StepLabel,
} from './CheckoutWizard.styled';

const CheckoutWizard = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const queryString = useQueryString();
  const currentStepId = parseInt(params.stepId, 10) || CHECKOUT_STEP.BILLING_ADDRESS.id;
  const paymentError = useRef();

  useLayoutEffect(() => {
    paymentError.current = null;
  }, [currentStepId]);

  const setActiveStep = useCallback(
    (modify) => {
      history.push(
        reverse(
          urls.checkout,
          { stepId: modify(currentStepId), organizationId: params.organizationId },
          queryString,
        ),
      );
    },
    [history, currentStepId, params.organizationId, queryString],
  );

  const handlePlanChange = useCallback(() => {
    history.push(reverse(urls.subscription, { organizationId: params.organizationId }));
  }, [history, params.organizationId]);

  const handleNext = useCallback(() => {
    const confirmationStepId = CHECKOUT_STEPS_LIST.length;

    if (currentStepId < confirmationStepId) {
      if (currentStepId + 1 === CHECKOUT_STEP.PAYMENT.id) {
        analytics.trackEvent(TRACK_EVENT_TYPE.INITIATE_PAYMENT);
      }
      if (currentStepId + 1 === CHECKOUT_STEP.CONFIRMATION.id) {
        analytics.trackEvent(TRACK_EVENT_TYPE.PAYMENT_SUCCESS_VIEWED);
      }
      setActiveStep(inc);
    }
  }, [currentStepId, setActiveStep]);

  const handleBack = useCallback(() => {
    if (currentStepId === CHECKOUT_STEP.BILLING_ADDRESS.id) {
      handlePlanChange();
    } else {
      setActiveStep(dec);
    }
  }, [currentStepId, handlePlanChange, setActiveStep]);

  const handlePaymentError = (error) => {
    paymentError.current = error;
    handleNext();
  };

  const renderNavigation = useCallback(
    ({ submitProps, stripeElement }) => (
      <Navigation>
        {stripeElement}
        <Button
          disabled={submitProps.pending}
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={handleBack}
          bordered
        >
          {t('checkoutWizard.back', 'Back')}
        </Button>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ActionButton type="submit" variant={BUTTON_VARIANT.PRIMARY} {...submitProps}>
          {currentStepId === CHECKOUT_STEP.PAYMENT.id
            ? t('checkoutWizard.pay', 'Pay')
            : t('checkoutWizard.next', 'Next')}
        </ActionButton>
      </Navigation>
    ),
    [handleBack, t, currentStepId],
  );

  const renderStep = cond([
    [
      equals(CHECKOUT_STEP.BILLING_ADDRESS.id),
      () => (
        <>
          <Description>
            {t('checkoutWizard.billingAddress.description', 'Please verify your billing address')}
          </Description>
          <BillingAddressForm onSubmit={handleNext} renderSubmit={renderNavigation} />
        </>
      ),
    ],
    [
      equals(CHECKOUT_STEP.PAYMENT.id),
      () => (
        <>
          <Description>
            {t('checkoutWizard.payment.description', 'Choose payment method')}
          </Description>
          <StripeElements>
            <PaymentForm
              planType={queryString.plan}
              planPeriod={queryString.period}
              onSubmit={handleNext}
              onError={handlePaymentError}
              renderSubmit={renderNavigation}
            />
          </StripeElements>
        </>
      ),
    ],
    [
      equals(CHECKOUT_STEP.CONFIRMATION.id),
      () => <PaymentStatus error={paymentError.current} plan={queryString.plan} />,
    ],
  ]);

  return (
    <Container className={className} data-testid="CheckoutWizard">
      <Stepper activeStep={currentStepId - 1}>
        {CHECKOUT_STEPS_LIST.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Content>
        {currentStepId !== CHECKOUT_STEP.CONFIRMATION.id && (
          <PlanSummary
            type={queryString.plan}
            period={queryString.period}
            onChange={handlePlanChange}
          />
        )}
        {renderStep(currentStepId)}
      </Content>
    </Container>
  );
};

export default CheckoutWizard;
