import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  selectSubscription,
  selectScheduledSubscription,
} from '~/modules/organization/organization.selectors';

import { Container, TextContent, Title, Description } from './SubscriptionChangeMessage.styled';

const SubscriptionChangeMessage = ({ className, onCancel }) => {
  const { t } = useTranslation();
  const currentSubscription = useSelector(selectSubscription);
  const scheduledSubscription = useSelector(selectScheduledSubscription);

  const { planType: currentPlan, planPeriod: currentPeriod } = currentSubscription;
  const { planType: scheduledPlan, planPeriod: scheduledPeriod } = scheduledSubscription || {};

  return (
    <Container
      className={className}
      aria-label={t('subscriptionChangeMessage.ariaLabel', 'Subscription change message')}
    >
      <Icon name="errorTriangle" />
      <TextContent>
        <Title>
          {t(
            'subscriptionChangeMessage.title',
            'Your current {{ plan, uppercase }} {{ period }} subscription is only active until {{ endDate, date }}',
            { plan: currentPlan, period: currentPeriod, endDate: currentSubscription.end },
          )}
        </Title>
        <Description>
          <Trans
            i18nKey="subscriptionChangeMessage.description"
            defaults="You've decided to change your subscription plan. Your new <strong>{{ plan, uppercase }} {{ period }} plan</strong> will start at the end of your billing period."
            values={{ plan: scheduledPlan, period: scheduledPeriod }}
          />
        </Description>
      </TextContent>
      <Button onClick={onCancel} variant={BUTTON_VARIANT.PRIMARY}>
        {t('subscriptionChangeMessage.cancelButton', 'Cancel change')}
      </Button>
    </Container>
  );
};

SubscriptionChangeMessage.defaultProps = {
  className: null,
};

SubscriptionChangeMessage.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

export default SubscriptionChangeMessage;
