import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectActivePaymentMethod } from '~/modules/organization/organization.selectors';

import { Container, TextContent, Title, Description } from './UnsuccessfulPaymentMessage.styled';

const UnsuccessfulPaymentMessage = ({ className }) => {
  const { t } = useTranslation();
  const paymentMethod = useSelector(selectActivePaymentMethod);

  return (
    <Container
      className={className}
      aria-label={t('unsuccessfulPaymentMessage.ariaLabel', 'Unsuccessful payment message')}
    >
      <Icon name="errorTriangle" />
      <TextContent>
        <Title>{t('unsuccessfulPaymentMessage.title', 'Unsuccessful payment')}</Title>
        <Description>
          <Trans
            i18nKey="unsuccessfulPaymentMessage.description"
            defaults="We were unable to charge your credit card. <strong>Please make sure you have sufficient funds</strong> on your card {{ brand }} **** {{ last4 }}.
            We will automatically keep trying to charge your card - after you top up your card you
            don't need to do anything."
            values={{ brand: paymentMethod.brand, last4: paymentMethod.last4 }}
          />
        </Description>
      </TextContent>
    </Container>
  );
};

UnsuccessfulPaymentMessage.defaultProps = {
  className: null,
};

UnsuccessfulPaymentMessage.propTypes = {
  className: PropTypes.string,
};

export default UnsuccessfulPaymentMessage;
