import { InputWrapper } from '@bugbug/core/components/Input';
import { HorizontalRadioGroup } from '@bugbug/core/components/RadioButton';
import { FONT_WEIGHT } from '@bugbug/core/theme';
import { ErrorMessage } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

export const RadioGroup = styled(HorizontalRadioGroup)`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`;

export const CardWrapper = styled(InputWrapper)`
  align-items: center;
  padding: 0 0 0 10px;

  > * {
    width: 100%;
  }

  + ${ErrorMessage} {
    margin: 0;
  }
`;
