import RadioButton from '@bugbug/core/components/RadioButton';
import { complement, prop } from 'ramda';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import {
  SUBSCRIPTION_LOWER_PLANS,
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_TYPE,
} from '~/constants/subscription';
import useModal from '~/hooks/useModal';
import {
  selectIsTrialAvailable,
  selectMonthlySubscriptions,
  selectParallelTestsLimit,
  selectScheduledSubscription,
  selectSubscription,
  selectYearlySubscriptions,
} from '~/modules/organization/organization.selectors';
import analytics, { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import CheckoutWizard from '../CheckoutWizard';
import CustomPlanMessage from '../CustomPlanMessage';
import SubscriptionCancelModal from '../SubscriptionCancelModal';
import SubscriptionChangeMessage from '../SubscriptionChangeMessage';
import SubscriptionItem from '../SubscriptionItem';
import TrialEndMessage from '../TrialEndMessage';
import TrialInfoMessage from '../TrialInfoMessage';
import UnsuccessfulPaymentMessage from '../UnsuccessfulPaymentMessage';

import { SUBSCRIPTION_PERIOD_DETAILS } from './Subscription.constants';
import { getInitialSubscriptionPeriod } from './Subscription.helpers';
import * as S from './Subscription.styled';

const Subscription = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { organizationId } = useParams();
  const subscription = useSelector(selectSubscription);
  const isTrialAvailable = useSelector(selectIsTrialAvailable);
  const monthlySubscriptions = useSelector(selectMonthlySubscriptions);
  const yearlySubscriptions = useSelector(selectYearlySubscriptions);

  const scheduledSubscription = useSelector(selectScheduledSubscription);
  const { planType, plan, planPeriod, isCustom, isFreePlan, isTrial } = subscription;
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(
    getInitialSubscriptionPeriod(isCustom, isFreePlan, isTrial, planPeriod),
  );
  const parallelTestsLimit = useSelector(selectParallelTestsLimit);
  const isLower = (type) => SUBSCRIPTION_LOWER_PLANS[planType].includes(type);
  const modal = useModal();
  const subscriptionsList =
    subscriptionPeriod === SUBSCRIPTION_PERIOD.MONTHLY ? monthlySubscriptions : yearlySubscriptions;

  const handlePeriodChange = useCallback((event) => {
    setSubscriptionPeriod(event.target.value);
  }, []);

  const handleUpgrade = (nextPlanType) => () => {
    analytics.trackEvent(TRACK_EVENT_TYPE.INITIATE_CHECKOUT, {
      [TRACK_EVENT_ARG_TYPE.SUBSCRIPTION_PERIOD]:
        SUBSCRIPTION_PERIOD_DETAILS[subscriptionPeriod]?.name || subscriptionPeriod,
      [TRACK_EVENT_ARG_TYPE.SUBSCRIPTION_TYPE]: nextPlanType,
    });
    history.push(
      reverse(
        urls.checkout,
        { stepId: 1, organizationId },
        { plan: nextPlanType, period: subscriptionPeriod },
      ),
    );
  };

  const renderSubscriptionCancelModal = useCallback(() => <SubscriptionCancelModal />, []);

  const handleDowngrade = (nextPlan) => () => {
    modal.show('downgrade_plan', { plan: nextPlan });
  };

  const handleCancel = () => {
    modal.showCustom(renderSubscriptionCancelModal);
  };

  const handleStartTrial = () => {
    modal.show('start_trial');
  };

  const handleContactUs = () => {
    window.open(
      t('subscription.contactUsUrl', 'https://bugbug.io/contact/?ref=subscription'),
      '_blank',
    );
  };

  const handleContactUsParallel = () => {
    analytics.trackEvent('business_plan_contact_clicked', {});
    handleContactUs();
  };

  const renderSubscriptionItem = (baseData) => {
    const isActive = subscription.isTrial
      ? baseData.plan === SUBSCRIPTION_TYPE.FREE
      : baseData.plan === plan && !subscription.isCustom;
    const isLowerPlan = isLower(baseData.planType);
    const isScheduled =
      scheduledSubscription && scheduledSubscription.plan.startsWith(baseData.planType);

    return (
      <SubscriptionItem
        key={baseData.planType}
        data={baseData}
        active={isActive}
        lower={isLowerPlan}
        scheduled={isScheduled}
        blocked={!!scheduledSubscription}
        onDowngrade={handleDowngrade(baseData.plan)}
        onUpgrade={handleUpgrade(baseData.planType)}
        onCancel={handleCancel}
        onContact={baseData.planType === 'parallel' ? handleContactUsParallel : handleContactUs}
      />
    );
  };

  const renderSubscriptionList = () => (
    <>
      {!!scheduledSubscription && <SubscriptionChangeMessage onCancel={handleCancel} />}
      {!!subscription.isTrial && <TrialEndMessage />}
      {!subscription.isTrial && isTrialAvailable && <TrialInfoMessage onStart={handleStartTrial} />}
      {!subscription.isTrial && subscription.problematicInvoice && <UnsuccessfulPaymentMessage />}
      {subscription.isCustom && (
        <CustomPlanMessage
          parallelRuns={parallelTestsLimit}
          planType={planType}
          onContact={handleContactUs}
        />
      )}
      <S.Header>
        <S.TitleWrapper>
          <S.Title>{t('subscription.title', 'Choose your plan')}</S.Title>
          <S.PriceTaxInfo>
            {t('subscription.priceTaxInfo', '*Prices do not include VAT')}
          </S.PriceTaxInfo>
        </S.TitleWrapper>
        <S.RadioGroup>
          <RadioButton
            onChange={handlePeriodChange}
            value={SUBSCRIPTION_PERIOD.MONTHLY}
            checked={subscriptionPeriod === SUBSCRIPTION_PERIOD.MONTHLY}
          >
            {t('subscription.period.monthly.label', 'Monthly')}
          </RadioButton>
          <RadioButton
            onChange={handlePeriodChange}
            value={SUBSCRIPTION_PERIOD.YEARLY}
            checked={subscriptionPeriod === SUBSCRIPTION_PERIOD.YEARLY}
          >
            {t('subscription.period.yearly.label', 'Annually')}
            <S.Discount>
              {t('default.subscription.discount', {
                discount: SUBSCRIPTION_PERIOD_DETAILS[SUBSCRIPTION_PERIOD.YEARLY].discount * 100,
              })}
            </S.Discount>
          </RadioButton>
        </S.RadioGroup>
      </S.Header>
      <S.SubscriptionPlans>
        {subscriptionsList.filter(complement(prop('isCustom'))).map(renderSubscriptionItem)}
        {renderSubscriptionItem({ planType: SUBSCRIPTION_TYPE.PARALLEL })}
      </S.SubscriptionPlans>
    </>
  );

  return (
    <S.Container className={className} data-testid="Subscription">
      <Switch>
        <Route path={urls.checkout} component={CheckoutWizard} exact />
        <Route path={urls.subscription} component={renderSubscriptionList} exact />
      </Switch>
    </S.Container>
  );
};

export default Subscription;
