import { InputWrapper } from '@bugbug/core/components/Input';
import { HorizontalRadioGroup } from '@bugbug/core/components/RadioButton';
import { FONT_WEIGHT, COLOR, hexToRgba } from '@bugbug/core/theme';
import { ErrorMessage } from '@bugbug/core/theme/typography';
import { CardElement as CardBase } from '@stripe/react-stripe-js';
import styled from 'styled-components';

import Stripe from '~/images/stripe.svg?react';

export const RadioGroup = styled(HorizontalRadioGroup)`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`;

const cardStyles = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: COLOR.GRAY_12,
      color: COLOR.DARK_GRAY,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '13px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: hexToRgba(COLOR.DARK_GRAY, 0.33),
      },
    },
    invalid: {
      iconColor: COLOR.ERROR,
      color: COLOR.ERROR,
    },
  },
};

export const CardWrapper = styled(InputWrapper)`
  align-items: center;
  padding: 0 0 0 10px;
  width: 100%;

  > * {
    width: 100%;
  }

  + ${ErrorMessage} {
    margin: 0;
  }
`;

export const CardField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
`;

export const CardElement = styled(CardBase).attrs(() => ({ options: cardStyles }))``;

export const CardGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;

  label {
    font-weight: ${FONT_WEIGHT.SEMIBOLD};
  }

  span {
    color: #7a7f86;
    line-height: 18px;
    margin-left: 28px;
    margin-top: -2px;
    display: block;
  }

  & + ${CardField} {
    margin-left: 26px;
  }
`;

export const PreviousCard = styled.div`
  margin-bottom: 20px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PoweredByStripe = styled(Stripe)``;
