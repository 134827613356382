import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import { selectCurrentOrganization } from '~/modules/organization/organization.selectors';
import * as validators from '~/utils/validators';

import { Row, Column, Label, SubmitButton } from '../../OrganizationSettings.styled';

const GeneralSchema = Yup.object().shape({
  name: validators.nameValidator,
});

const General = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganization = useSelector(selectCurrentOrganization);

  const handleOnSubmit = (values) => {
    dispatch(OrganizationActions.updateOrganizationNameRequest(values.name));
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setErrors,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: currentOrganization.name,
    },
    validationSchema: GeneralSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isLoading, isSuccess } = useActionState(
    OrganizationActions.updateOrganizationNameRequest,
    {
      onFailure: handleFailure,
      onSuccess: handleSuccess,
    },
  );

  return (
    <form noValidate data-testid="General" onSubmit={handleSubmit}>
      <Row>
        <Column>
          <Label>{t('organizationSettings.general.form.name.label', 'Organization name')}</Label>
        </Column>
        <Column>
          <Input
            data-hj-allow
            type="text"
            name="name"
            placeholder={t(
              'organizationSettings.general.form.name.placeholder',
              'Enter organization name',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={touched.name && errors.name}
            fullWidth
          />
        </Column>
      </Row>

      <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
        {t('default.button.save')}
      </SubmitButton>
    </form>
  );
};

export default General;
