import Dropdown, {
  DROPDOWN_ANCHOR,
  DROPDOWN_VARIANT,
  DropdownItem,
} from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { USER_ROLE, USER_ROLE_LABELS } from '~/modules/constans';
import { selectUserData } from '~/modules/user/user.selectors';

import { Container } from './UserRowActions.styled';

const UserRowActions = ({ className, row }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { role, email } = row.original;

  const currentUser = useSelector(selectUserData);
  const isAdmin = role === USER_ROLE.ADMIN;
  const newRoleLabel = isAdmin
    ? USER_ROLE_LABELS[USER_ROLE.EDITOR]
    : USER_ROLE_LABELS[USER_ROLE.ADMIN];

  const handleChangeRoleClick = useCallback(
    () =>
      modal.show(MODAL_TYPE.CHANGE_USER_ROLE, {
        email,
        role,
      }),
    [modal, email, role],
  );

  const handleRemoveClick = useCallback(
    () => modal.show(MODAL_TYPE.DELETE_USER, { email }),
    [modal, email],
  );

  const hiddenActions = useMemo(
    () => [
      {
        name: t('userRowActions.change.label', 'Change to "{{value}}"', { value: newRoleLabel }),
        onClick: handleChangeRoleClick,
        iconName: 'edit',
      },
      {
        name: t('userRowActions.remove.label', 'Remove access'),
        danger: true,
        onClick: handleRemoveClick,
        iconName: 'delete',
      },
    ],
    [newRoleLabel, handleRemoveClick, handleChangeRoleClick, t],
  );

  const renderMoreActions = () => (
    <Dropdown
      data-testid="UserRowActions.Dropdown"
      variant={DROPDOWN_VARIANT.ICON}
      anchor={DROPDOWN_ANCHOR.BOTTOM_END}
      iconName="more"
    >
      {hiddenActions.map(({ name, onClick, iconName, disabled, danger }) => (
        <DropdownItem key={name} disabled={disabled} onClick={onClick} danger={danger}>
          <Icon name={iconName} /> {name}
        </DropdownItem>
      ))}
    </Dropdown>
  );

  if (currentUser.email === email) {
    return null;
  }

  return (
    <Container className={className} data-testid="UserRowActions">
      {renderMoreActions()}
    </Container>
  );
};

UserRowActions.defaultProps = {
  className: null,
};

UserRowActions.propTypes = {
  className: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      role: PropTypes.oneOf(Object.values(USER_ROLE)).isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default UserRowActions;
