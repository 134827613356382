import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { useTranslation } from 'react-i18next';

import { Container, TextContent, Title, Description } from './CustomPlanMessage.styled';

import LogoVert from '~/images/logo-vertical.svg?react';

const CustomPlanMessage = ({ className, onContact, planType, parallelRuns }) => {
  const { t } = useTranslation();

  return (
    <Container
      className={className}
      aria-label={t('customPlanMessage.ariaLabel', 'Your current plan')}
    >
      <LogoVert />
      <TextContent>
        <Title>
          {t(
            'customPlanMessage.title',
            'Your current plan: {{planType, uppercase}} with {{parallelRuns}} parallel runs',
            {
              parallelRuns,
              planType,
            },
          )}
        </Title>
        <Description>
          {t(
            'customPlanMessage.description',
            'You are using a subscription plan that has been specially customized to you and is not directly listed in our price list. To modify it, please contact customer service.',
          )}
        </Description>
      </TextContent>
      <Button onClick={onContact} variant={BUTTON_VARIANT.PRIMARY}>
        {t('customPlanMessage.button', 'Contact support')}
      </Button>
    </Container>
  );
};

export default CustomPlanMessage;
