import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SUBSCRIPTION_TYPE } from '~/constants/subscription';
import { selectSubscription } from '~/modules/organization/organization.selectors';
import { getDaysLeft } from '~/utils/dates';

import { Container, TextContent, Title, Description } from './TrialEndMessage.styled';

const TrialEndMessage = ({ className }) => {
  const { t } = useTranslation();
  const currentSubscription = useSelector(selectSubscription);

  const { planType: currentPlan, end: endDate } = currentSubscription;

  return (
    <Container
      className={className}
      aria-label={t('trialEndMessage.ariaLabel', 'Trial end message')}
    >
      <Icon name="clock" />
      <TextContent>
        <Title>
          {t('trialEndMessage.title', {
            defaultValue_one: '1 day left on a free trial',
            defaultValue_other: '{{ count }} days left on a free trial',
            count: getDaysLeft(endDate),
          })}
        </Title>
        <Description>
          {t(
            'trialEndMessage.description',
            'You\'re currently on a free trial that allows you to use our paid plan "{{ trialPlan, uppercase }}" for free. The trial will end on {{ endDate, date }}, after that you will go automatically go back to plan "{{ freePlan, uppercase }}".',
            { trialPlan: currentPlan, freePlan: SUBSCRIPTION_TYPE.FREE, endDate },
          )}
        </Description>
      </TextContent>
    </Container>
  );
};

TrialEndMessage.defaultProps = {
  className: null,
};

TrialEndMessage.propTypes = {
  className: PropTypes.string,
};

export default TrialEndMessage;
