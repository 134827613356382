import { always, cond, equals } from 'ramda';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useMount } from 'react-use';

import Tabs, { Tab } from '~/components/Tabs';
import { selectIsOrganizationAdmin } from '~/modules/organization/organization.selectors';
import urls, { reverse } from '~/views/urls';

import BillingAddress from './components/BillingAddress';
import CreditCard from './components/CreditCard';
import General from './components/General';
import Invoices from './components/Invoices';
import Subscription from './components/Subscription';
import UsersSettings from './components/UsersSettings';
import {
  ORG_SETTINGS_TAB as TAB,
  ORG_SETTINGS_TAB_LABEL as TAB_LABEL,
} from './OrganizationSettings.constants';
import { Container, TabContent } from './OrganizationSettings.styled';

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const { settingsTabSlug, organizationId } = useParams();
  const history = useHistory();
  const isAdmin = useSelector(selectIsOrganizationAdmin);
  const isProperTab = Object.values(TAB).includes(settingsTabSlug);
  const initialTab = isProperTab ? settingsTabSlug : TAB.GENERAL;
  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    setSelectedTab(settingsTabSlug);
  }, [settingsTabSlug]);

  const handleTabChange = useCallback(
    (event, value) => {
      history.push(
        reverse(urls.organizationSettings, {
          settingsTabSlug: value,
          organizationId,
        }),
      );
      setSelectedTab(value);
    },
    [history, organizationId],
  );

  useMount(() => {
    if (!isProperTab && isAdmin) {
      handleTabChange(null, initialTab);
    }
  });

  const renderTabContent = cond([
    [equals(TAB.GENERAL), always(<General />)],
    [equals(TAB.USERS), always(<UsersSettings />)],
    [equals(TAB.SUBSCRIPTION), always(<Subscription />)],
    [equals(TAB.BILLING_ADDRESS), always(<BillingAddress />)],
    [equals(TAB.CREDIT_CARD), always(<CreditCard />)],
    [equals(TAB.INVOICES), always(<Invoices />)],
  ]);

  const HeaderContent = useMemo(
    () => (
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label={TAB_LABEL[TAB.GENERAL]} value={TAB.GENERAL} />
        <Tab label={TAB_LABEL[TAB.USERS]} value={TAB.USERS} />
        <Tab label={TAB_LABEL[TAB.BILLING_ADDRESS]} value={TAB.BILLING_ADDRESS} />
        <Tab label={TAB_LABEL[TAB.SUBSCRIPTION]} value={TAB.SUBSCRIPTION} />
        <Tab label={TAB_LABEL[TAB.CREDIT_CARD]} value={TAB.CREDIT_CARD} />
        <Tab label={TAB_LABEL[TAB.INVOICES]} value={TAB.INVOICES} />
      </Tabs>
    ),
    [handleTabChange, selectedTab],
  );

  if (!isAdmin) {
    return <Redirect to={reverse(urls.projectsList, { organizationId })} />;
  }

  return (
    <Container
      data-testid="OrganizationSettings"
      title={t('organizationSettings.title', 'Organization settings')}
      centeredContent
      withBackButton
      HeaderContent={HeaderContent}
    >
      <Helmet
        title={t('organizationSettings.pageTitle', 'Organization settings / {{ tab }}', {
          tab: TAB_LABEL[selectedTab],
        })}
      />
      <TabContent>{renderTabContent(settingsTabSlug)}</TabContent>
    </Container>
  );
};

export default OrganizationSettings;
