import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, TextContent, Title, Description } from './TrialInfoMessage.styled';

import LogoVert from '~/images/logo-vertical.svg?react';

const TrialInfoMessage = ({ className, onStart }) => {
  const { t } = useTranslation();

  return (
    <Container
      className={className}
      aria-label={t('trialInfoMessage.ariaLabel', 'Try a paid plan for free')}
    >
      <LogoVert />
      <TextContent>
        <Title>{t('trialInfoMessage.title', 'Try a paid plan for free')}</Title>
        <Description>
          {t(
            'trialInfoMessage.description',
            'Start your 14-day trial and test out paid features for free. No credit card required.',
          )}
        </Description>
      </TextContent>
      <Button onClick={onStart} variant={BUTTON_VARIANT.PRIMARY}>
        {t('trialInfoMessage.startButton', 'Start a free trial')}
      </Button>
    </Container>
  );
};

TrialInfoMessage.defaultProps = {
  className: null,
};

TrialInfoMessage.propTypes = {
  className: PropTypes.string,
  onStart: PropTypes.func.isRequired,
};

export default TrialInfoMessage;
