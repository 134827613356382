import Button from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { MouseEventHandler } from 'react';

import type { PlanPeriod, PlanType } from '@bugbug/core/types/subscriptions';
import { selectSubscriptionByTypeAndPeriod } from '~/modules/organization/organization.selectors';

import {
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_PERIOD_DETAILS,
} from '../Subscription/Subscription.constants';

import * as S from './PlanSummary.styled';

interface PlanSummaryProps {
  type: PlanType;
  period: PlanPeriod;
  onChange: MouseEventHandler<HTMLButtonElement>;
}

const PlanSummary = ({ type, period, onChange }: PlanSummaryProps) => {
  const { t } = useTranslation();
  const selectedSubscription = useSelector(selectSubscriptionByTypeAndPeriod(type, period));

  return (
    <S.Container aria-label="Plan summary">
      <Icon name="cart" />
      <S.Column>
        <S.Type>{t('planSummary.plan.label', 'Plan:')}</S.Type>
        <S.Value>{SUBSCRIPTION_DETAILS[type].name}</S.Value>
      </S.Column>
      <S.Column>
        <S.Type>{t('planSummary.billed.label', 'Billed:')}</S.Type>
        <S.Value>{SUBSCRIPTION_PERIOD_DETAILS[period].name}</S.Value>
      </S.Column>
      <S.Column>
        <S.Type>{t('planSummary.price.label', 'Price:')}</S.Type>
        <S.Value>
          {/* eslint-disable-next-line no-template-curly-in-string */}
          {t('planSummary.price.value', '${{ price }} / month', {
            price: selectedSubscription?.pricePerMonth,
          })}
        </S.Value>
      </S.Column>
      <S.Column>
        <Button onClick={onChange}>{t('planSummary.changeButton', 'Change')}</Button>
      </S.Column>
    </S.Container>
  );
};

export default PlanSummary;
