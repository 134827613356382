import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import { Content as ContentBase } from '~/components/modals/Modal';

export const Container = styled.div``;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 15px;

  svg {
    color: ${COLOR.GRAY_34};
    width: 90px;
    height: 54px;
    margin-right: 16px;
  }

  p:last-child {
    margin: 0;
  }
`;
