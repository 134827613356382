import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { omit } from 'ramda';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useMount } from 'react-use';

import ServerErrorInfo from '~/components/ServerErrorInfo';
import { useRecentSort } from '~/components/Table';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { QUERY_ACTION, USER_ROLE_LABELS } from '~/modules/constans';
import { OrganizationUsersActions, selectOrganizationUsersList } from '~/modules/organizationUsers';
import { reverse } from '~/views/urls';

import { USERS_LIST_COLUMNS } from './UsersSettings.constants';
import {
  Container,
  Header,
  Description,
  InviteButton,
  ListContainer,
  LoaderWrapper,
  Table,
} from './UsersSettings.styled';

const defaultSort = {
  sortBy: 'email',
  desc: true,
};

const UsersSettings = ({ className }) => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryString();
  const modal = useModal();
  const { isLoading, hasInternalServerError, hasBadRequestError } = useActionState(
    OrganizationUsersActions.getListRequest,
  );
  const users = useSelector(selectOrganizationUsersList);

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'usersList',
    defaultConfig: defaultSort,
    columns: USERS_LIST_COLUMNS,
  });

  const handleDataUpdate = useCallback(
    (tableData = tableRef.current.state) => {
      const { sortBy, desc } = tableData.sortConfig;
      dispatch(OrganizationUsersActions.getListRequest(sortBy, desc));
      updateRecentSort(tableData.sortConfig);
    },
    [dispatch, updateRecentSort],
  );

  const handleSortChange = useCallback(
    (sortConfig) => {
      handleDataUpdate({ sortConfig });
    },
    [handleDataUpdate],
  );

  const fetchInitialData = useCallback(() => {
    handleDataUpdate({ ...tableRef.current.state });
  }, [handleDataUpdate]);

  const showInviteUserModal = useCallback(
    () =>
      modal.show(
        'invite_user',
        {},
        {
          onHide: () => {
            history.replace(reverse(history.location.pathname, {}, omit(['action'], queryParams)));
          },
        },
      ),
    [history, modal, queryParams],
  );

  useMount(() => {
    fetchInitialData();
  });

  useEffect(() => {
    if (queryParams.action === QUERY_ACTION.SHOW_USER_INVITATION) {
      showInviteUserModal();
    }
  }, [queryParams.action, showInviteUserModal]);

  const renderLoader = renderWhenTrue(() => {
    if (hasInternalServerError || hasBadRequestError) {
      return (
        <ServerErrorInfo
          isVisible={hasInternalServerError || hasBadRequestError}
          onRetry={handleDataUpdate}
          hideRetryButton={hasBadRequestError}
        />
      );
    }
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  });

  return (
    <Container className={className} data-testid="UsersSettings">
      <Header>
        <Description>
          {t(
            'usersSettings.description',
            'Invite teammates to collaborate on all your projects. Below you can see who already has access.',
          )}
        </Description>
        <InviteButton onClick={showInviteUserModal}>
          {t('usersSettings.inviteButtonLabel', 'Invite new user')}
        </InviteButton>
      </Header>
      <ListContainer>
        {renderLoader(isLoading || hasInternalServerError || hasBadRequestError)}
        <Table
          ref={tableRef}
          columns={USERS_LIST_COLUMNS}
          data={users.map((user) => ({
            ...user,
            roleName: USER_ROLE_LABELS[user.role],
          }))}
          onSortChange={handleSortChange}
          defaultSortBy={defaultSort.sortBy}
          defaultDescOrder={defaultSort.desc}
          initialSortBy={recentSort.sortBy}
          initialDescOrder={recentSort.desc}
          disabledWindowing
        />
      </ListContainer>
    </Container>
  );
};

export default UsersSettings;
