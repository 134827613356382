import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

import Card from '~/components/Card';

export const Container = styled(Card)`
  flex-direction: row;
  min-height: 100px;
  padding: 16px 32px;
  align-items: center;
  margin-bottom: 40px;
  border: 2px solid ${COLOR.SECONDARY};
  border-radius: 11px;
  box-shadow: none;

  svg {
    width: auto;
    height: 48px;
    margin-right: 16px;
    stroke: ${COLOR.SECONDARY};
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled.p`
  margin-bottom: 5px;
  margin-top: -2px;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const Description = styled.p`
  line-height: 19px;
  margin-bottom: 0;
`;
