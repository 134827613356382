import Input from '@bugbug/core/components/Input';
import RadioButton from '@bugbug/core/components/RadioButton';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import Tooltip from '@bugbug/core/components/Tooltip';
import { ErrorMessage } from '@bugbug/core/theme/typography';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { propOr, last, isNil } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import { selectCurrentOrganization } from '~/modules/organization/organization.selectors';
import * as validators from '~/utils/validators';

import { Row, Column, HelpIcon, Label, Form } from '../../OrganizationSettings.styled';

import { COUNTRIES } from './BillingAddressForm.constants';
import { isBusinessAccount, isPersonalAccount } from './BillingAddressForm.helpers';
import { RadioGroup } from './BillingAddressForm.styled';

const BillingAddressFormSchema = Yup.object({
  isCompany: Yup.boolean(validators.VALIDATION_MESSAGE.REQUIRED)
    .nullable()
    .typeError(validators.VALIDATION_MESSAGE.REQUIRED)
    .required(validators.VALIDATION_MESSAGE.REQUIRED),
  address: Yup.object().shape({
    id: Yup.string(),
    country: validators.nameValidator,
    city: validators.nameValidator,
    zipCode: validators.zipCodeValidator,
    streetLine1: validators.nameValidator,
  }),
});

const PersonalAddressFormSchema = BillingAddressFormSchema.shape({
  billingFirstName: validators.nameValidator,
  billingLastName: validators.nameValidator,
});

const CompanyAddressSchema = BillingAddressFormSchema.shape({
  companyName: validators.nameValidator.ensure(),
  taxId: validators.nullableString,
});

const BillingAddressForm = ({ onSubmit, renderSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(selectCurrentOrganization);

  const handleOnSubmit = useCallback(
    (values) => {
      const schema = isBusinessAccount(values) ? CompanyAddressSchema : PersonalAddressFormSchema;
      dispatch(
        OrganizationActions.updateOrganizationRequest(schema.cast(values, { stripUnknown: true })),
      );
    },
    [dispatch],
  );

  const address = useMemo(
    () =>
      propOr(
        {
          country: last(window.navigator.language.split('-')).toUpperCase(),
          city: '',
          streetLine1: '',
          zipCode: '',
        },
        0,
        organization.addresses,
      ),
    [organization.addresses],
  );

  const getValidationSchema = useCallback(
    () =>
      Yup.lazy((values) =>
        isBusinessAccount(values) ? CompanyAddressSchema : PersonalAddressFormSchema,
      ),
    [],
  );

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setErrors,
    setSubmitting,
    setTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      isCompany: `${isNil(organization.isCompany) ? true : organization.isCompany}`,
      taxId: organization.taxId,
      companyName: organization.companyName,
      billingFirstName: organization.billingFirstName || '',
      billingLastName: organization.billingLastName || '',
      address,
    },
    validationSchema: getValidationSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    onSubmit();
    setSubmitting(false);
    setTouched(Object.fromEntries(Object.keys(values).map((key) => [key, false])));
  }, [onSubmit, setSubmitting, setTouched, values]);

  const { isLoading, isSuccess, reset } = useActionState(
    OrganizationActions.updateOrganizationRequest,
    {
      reset: false,
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    },
  );

  useUnmount(() => {
    reset();
  });

  const taxIdTooltip = t(
    'accountSettings.billingAddress.form.taxId.tooltip',
    "Enter your company's tax identification number for the invoice. If you are from the European Union, enter your EU VAT number that starts with your country code, for example DE123456789.",
  );

  const addressErrors = propOr({}, 'address', errors);
  const addressTouched = propOr({}, 'address', touched);

  const renderCountry = useCallback(
    ({ name, code }) => (
      <SelectOption key={code} value={code}>
        {name}
      </SelectOption>
    ),
    [],
  );

  const renderCompanyNameField = renderWhenTrue(() => (
    <>
      <Row>
        <Column>
          <Label>
            {t('accountSettings.billingAddress.form.companyName.label', 'Company name')}
          </Label>
        </Column>
        <Column>
          <Input
            type="text"
            name="companyName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.companyName}
            error={touched.companyName && errors.companyName}
            fullWidth
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Tooltip content={taxIdTooltip}>
            <Label>
              {t('accountSettings.billingAddress.form.taxId.label', 'Tax ID')} <HelpIcon />
            </Label>
          </Tooltip>
        </Column>
        <Column>
          <Input
            type="text"
            name="taxId"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.taxId}
            error={touched.taxId && errors.taxId}
            placeholder={t(
              'accountSettings.billingAddress.form.taxId.placeholder',
              'ex. PL34543454',
            )}
            fullWidth
          />
        </Column>
      </Row>
    </>
  ));

  const renderUserNamesFields = renderWhenTrue(() => (
    <>
      <Row>
        <Column>
          <Label>
            {t('accountSettings.billingAddress.form.billingFirstName.label', 'First name')}
          </Label>
        </Column>
        <Column>
          <Input
            type="text"
            name="billingFirstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.billingFirstName}
            error={touched.billingFirstName && errors.billingFirstName}
            fullWidth
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>
            {t('accountSettings.billingAddress.form.billingLastName.label', 'Last name')}
          </Label>
        </Column>
        <Column>
          <Input
            type="text"
            name="billingLastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.billingLastName}
            error={touched.billingLastName && errors.billingLastName}
            fullWidth
          />
        </Column>
      </Row>
    </>
  ));

  return (
    <Form noValidate data-testid="BillingAddressForm" onSubmit={handleSubmit}>
      <Row>
        <Column>
          <Label>{t('accountSettings.billingAddress.form.type.label', 'Account type')}</Label>
        </Column>
        <Column>
          <div>
            <RadioGroup>
              <RadioButton
                name="isCompany"
                onChange={handleChange}
                value="false"
                checked={isPersonalAccount(values)}
              >
                {t('accountSettings.billingAddress.form.type.personal', 'Personal')}
              </RadioButton>
              <RadioButton
                name="isCompany"
                onChange={handleChange}
                value="true"
                checked={isBusinessAccount(values)}
              >
                {t('accountSettings.billingAddress.form.type.business', 'Business')}
              </RadioButton>
            </RadioGroup>
            {touched.isCompany && errors.isCompany && (
              <ErrorMessage>{errors.isCompany}</ErrorMessage>
            )}
          </div>
        </Column>
      </Row>
      {renderUserNamesFields(!isBusinessAccount(values))}
      {renderCompanyNameField(isBusinessAccount(values))}
      <Row>
        <Column>
          <Label>{t('accountSettings.billingAddress.form.country.label', 'Country')}</Label>
        </Column>
        <Column>
          <Select
            name="address.country"
            placeholder={t(
              'accountSettings.billingAddress.form.country.placeholder',
              'Select country',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address.country}
            error={addressTouched.country && addressErrors.country}
            fullWidth
            showSearch
          >
            {COUNTRIES.map(renderCountry)}
          </Select>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>
            {t('accountSettings.billingAddress.form.streetLine1.label', 'Street and address')}
          </Label>
        </Column>
        <Column>
          <Input
            type="text"
            name="address.streetLine1"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address.streetLine1}
            error={addressTouched.streetLine1 && addressErrors.streetLine1}
            placeholder={t(
              'accountSettings.billingAddress.form.streetLine1.placeholder',
              'ex. Mainroad 12/23',
            )}
            fullWidth
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>{t('accountSettings.billingAddress.form.zipCode.label', 'Postal code')}</Label>
        </Column>
        <Column>
          <Input
            type="text"
            name="address.zipCode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address.zipCode}
            error={addressTouched.zipCode && addressErrors.zipCode}
            fullWidth
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>{t('accountSettings.billingAddress.form.city.label', 'City')}</Label>
        </Column>
        <Column>
          <Input
            type="text"
            name="address.city"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address.city}
            error={addressTouched.city && addressErrors.city}
            fullWidth
          />
        </Column>
      </Row>
      {renderSubmit({
        submitProps: { pending: isLoading, disabled: isSubmitting, succeeded: isSuccess },
      })}
    </Form>
  );
};

BillingAddressForm.propTypes = {
  renderSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

BillingAddressForm.defaultProps = {
  onSubmit: Function.prototype,
};

export default BillingAddressForm;
