import Icon from '@bugbug/core/components/Icon';
import Tooltip from '@bugbug/core/components/Tooltip';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SUBSCRIPTION_TYPE } from '~/constants/subscription';
import {
  selectSubscription,
  selectScheduledSubscription,
} from '~/modules/organization/organization.selectors';

import { SUBSCRIPTION_DETAILS } from '../Subscription/Subscription.constants';

import {
  Container,
  Name,
  Description,
  Features,
  Feature,
  Button,
  CurrentPlan,
  Price,
  ExtraMessage,
  CancelButton,
  HelperIcon,
} from './SubscriptionItem.styled';

const SubscriptionItem = (props) => {
  const {
    className,
    data,
    active,
    lower,
    blocked,
    scheduled,
    onDowngrade,
    onUpgrade,
    onCancel,
    onContact,
  } = props;
  const { t } = useTranslation();
  const details = SUBSCRIPTION_DETAILS[data.planType];
  const currentSubscription = useSelector(selectSubscription);
  const scheduledSubscription = useSelector(selectScheduledSubscription);
  const isParallelType = data.planType === SUBSCRIPTION_TYPE.PARALLEL;

  const handleSelectPlan = () => {
    if (!lower) {
      onUpgrade();
    } else {
      onDowngrade();
    }
  };

  const renderCancelMessage = renderWhenTrue(() => (
    <>
      <ExtraMessage>
        {scheduled &&
          t(
            'subscription.cancelMessage.scheduled',
            'This will be your new plan starting from {{ startDate, date }}',
            {
              startDate: scheduledSubscription.start,
            },
          )}
        {active &&
          t(
            'subscription.cancelMessage.active',
            'This is your current plan that will remain active until {{ endDate, date }}',
            { endDate: currentSubscription.end },
          )}
        {!scheduled &&
          !active &&
          t('subscription.cancelMessage.others', 'To select this plan you need to')}
      </ExtraMessage>
      {!scheduled && (
        <CancelButton onClick={onCancel}>
          {t('subscription.cancelButton', 'Cancel change')}
        </CancelButton>
      )}
    </>
  ));

  const renderCurrentPlanButton = renderWhenTrue(() => (
    <CurrentPlan>
      <Icon name="checkCircle" />
      {t('subscription.currentPlan', 'Current plan')}
    </CurrentPlan>
  ));

  const renderUpgradeButton = renderWhenTrue(() => (
    <Button onClick={handleSelectPlan}>{t('subscription.selectButton', 'Select')}</Button>
  ));

  const renderContactButton = renderWhenTrue(() => (
    <Button onClick={onContact}>{t('default.button.contactUs')}</Button>
  ));

  const renderButtons = renderWhenTrue(() => (
    <>
      {renderCurrentPlanButton(active && !isParallelType)}
      {renderUpgradeButton(!active && !isParallelType)}
      {renderContactButton(isParallelType)}
    </>
  ));

  if (!details) {
    return null;
  }

  return (
    <Container className={className} data-testid="SubscriptionItem">
      <Name>{details.name}</Name>
      <Description>{details.description}</Description>
      <Price>
        {!isParallelType ? (
          <Trans i18nKey="default.subscription.price" values={{ price: data.pricePerMonth }} />
        ) : (
          <p>{t('default.button.contactUs')}</p>
        )}
      </Price>
      {renderButtons(!blocked)}
      <Features>
        {details.features.map((feature, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tooltip content={feature.tooltip} key={index}>
            <Feature disabled={feature.disabled} important={feature.important}>
              {feature.label} {feature.tooltip ? <HelperIcon /> : null}
            </Feature>
          </Tooltip>
        ))}
      </Features>
      {renderCancelMessage(blocked)}
    </Container>
  );
};

SubscriptionItem.defaultProps = {
  className: null,
  active: false,
  lower: false,
  blocked: false,
  scheduled: false,
};

SubscriptionItem.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  lower: PropTypes.bool,
  blocked: PropTypes.bool,
  scheduled: PropTypes.bool,
  onDowngrade: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.shape({
    pricePerMonth: PropTypes.string,
    planType: PropTypes.oneOf(Object.values(SUBSCRIPTION_TYPE)),
  }).isRequired,
};

export default SubscriptionItem;
