import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import useActionState from '~/hooks/useActionState';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import { selectInvoices } from '~/modules/organization/organization.selectors';

import { Container, LoaderContainer, ListItem, EmptyState } from './Invoices.styled';

const Invoices = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const invoices = useSelector(selectInvoices);
  const { isLoading } = useActionState(OrganizationActions.getInvoicesRequest);

  useMount(() => {
    dispatch(OrganizationActions.getInvoicesRequest());
  });

  const renderLoader = renderWhenTrue(() => (
    <LoaderContainer>
      <Loader size="large" />
    </LoaderContainer>
  ));

  const renderList = renderWhenTrue(() =>
    invoices.map(({ id, file, periodStart, periodEnd, total, currency }) => (
      <ListItem key={id}>
        {t(
          'accountSettings.invoices.invoiceData',
          '{{ startDate }} - {{ endDate }} / {{ price }} {{ currency }}',
          {
            startDate: dayjs(periodStart).format('DD MMM YYYY'),
            endDate: dayjs(periodEnd).format('DD MMM YYYY'),
            price: total,
            currency: currency.toUpperCase(),
          },
        )}
        {file && (
          <Link to={file} download>
            {t('accountSettings.invoices.downloadButton', 'Download PDF')}
          </Link>
        )}
      </ListItem>
    )),
  );

  return (
    <Container className={className} data-testid="Invoices">
      <EmptyState
        isVisible={!isLoading && !invoices.length}
        text={t('accountSettings.invoices.emptyList', 'You have no invoices yet')}
      />
      {renderLoader(isLoading)}
      {renderList(!isLoading && !!invoices.length)}
    </Container>
  );
};

Invoices.defaultProps = {
  className: null,
};

Invoices.propTypes = {
  className: PropTypes.string,
};

export default Invoices;
