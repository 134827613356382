import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { AddCircleIcon, COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

import { SimpleTable, TableBody, TableRow } from '~/components/Table';

export const Container = styled.div`
  max-width: 750px;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  margin-bottom: 32px;
`;

export const Description = styled.p`
  margin: 0;
  max-width: 330px;
  color: ${COLOR.GRAY_19};
  font-size: 13px;
  line-height: 18px;
  padding: 3px 10px 3px 0;
`;

export const InviteButton = styled(Button).attrs(() => ({
  Icon: AddCircleIcon,
  variant: BUTTON_VARIANT.PRIMARY,
}))``;

export const ListContainer = styled.div`
  position: relative;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
  height: 100%;
  background-color: ${COLOR.WHITE};
  top: 42px;
  padding: 50px;
`;

export const Table = styled(SimpleTable)`
  ${TableBody} {
    border-bottom: 0;

    ${TableRow}:hover {
      background-color: inherit;
      border-top: 1px solid ${COLOR.TRANSPARENT};
    }
  }

  ${TableRow}:hover {
    border-bottom: 1px solid ${COLOR.GRAY_18};
  }
`;
