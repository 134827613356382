import { HorizontalRadioGroup } from '@bugbug/core/components/RadioButton';
import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled.h2`
  display: flex;
  min-height: 60px;
  align-items: center;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.BOLD};
  margin: 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceTaxInfo = styled.span`
  margin-left: 16px;
  line-height: 17px;
  color: ${COLOR.GRAY_12};
`;

export const SubscriptionPlans = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 40px;
  justify-content: stretch;
  overflow: hidden;
`;

export const RadioGroup = styled(HorizontalRadioGroup)`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`;

export const Discount = styled.span`
  color: ${COLOR.SUCCESS};
  font-weight: ${FONT_WEIGHT.BOLD};
  text-transform: uppercase;
  margin-left: 4px;
`;
