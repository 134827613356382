import { FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

import Card from '~/components/Card';

export const Container = styled(Card)`
  flex-direction: row;
  min-height: 100px;
  padding: 16px 32px;
  align-items: center;
  margin-bottom: 40px;

  svg {
    width: auto;
    height: 48px;
    margin-right: 22px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: -4px;
`;

export const Title = styled.p`
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const Description = styled.p`
  line-height: 19px;
  margin-bottom: 0;
`;
