import type { PlanPeriod } from '@bugbug/core/types/subscriptions';
import { SUBSCRIPTION_PERIOD } from '~/constants/subscription';

export const getInitialSubscriptionPeriod = (
  isCustom: boolean,
  isFreePlan: boolean,
  isTrial: boolean,
  planPeriod: PlanPeriod,
): PlanPeriod => {
  switch (true) {
    case isCustom:
      return SUBSCRIPTION_PERIOD.MONTHLY as 'monthly';

    case isTrial:
    case isFreePlan:
      return SUBSCRIPTION_PERIOD.YEARLY as 'yearly';

    default:
      return planPeriod;
  }
};
