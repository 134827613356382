import { H1, RegularParagraph } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 25px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const Amount = styled(H1)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: -3px;
`;

export const Label = styled(RegularParagraph)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Period = styled(RegularParagraph)`
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const VATNote = styled(RegularParagraph)`
  color: ${({ theme }) => theme.colors.text.emptyState};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-align: right;
  width: 100%;
  margin-top: -8px;
`;
