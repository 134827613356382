import { countries } from 'countries-list';
import { sortBy, prop } from 'ramda';

export const COUNTRIES = sortBy(
  prop('name'),
  Object.keys(countries).map((code) => ({
    ...countries[code],
    code,
  })),
);
