import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Tooltip from '@bugbug/core/components/Tooltip';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useModal from '~/hooks/useModal';
import {
  selectActivePaymentMethod,
  selectSubscription,
} from '~/modules/organization/organization.selectors';
import urls, { reverse } from '~/views/urls';

import { Row, Column, Label } from '../../OrganizationSettings.styled';
import UpdateCardModal from '../UpdateCardModal';

import { Container, Actions, EmptyState, RemoveCardButtonWrapper } from './CreditCard.styled';

const CreditCard = ({ className }) => {
  const { t } = useTranslation();
  const activePaymentMethod = useSelector(selectActivePaymentMethod);
  const subscription = useSelector(selectSubscription);
  const modal = useModal();
  const history = useHistory();
  const { organizationId } = useParams();

  const renderModalContent = useCallback(() => <UpdateCardModal />, []);

  const openUpdateModal = useCallback(
    () => modal.showCustom(renderModalContent),
    [modal, renderModalContent],
  );

  if (!activePaymentMethod.id) {
    return (
      <EmptyState
        isVisible
        text={t('creditCard.emptyState.message', 'You haven’t added a credit card yet.')}
        action={{
          text: t('creditCard.emptyState.button', 'Subscribe'),
          onClick: () => history.push(reverse(urls.subscription, { organizationId })),
        }}
      />
    );
  }

  return (
    <Container className={className} data-testid="CreditCard">
      <Row>
        <Column>
          <Label>{t('creditCard.number.label', 'Card number')}</Label>
        </Column>
        <Column>
          {t('creditCard.number.value', '{{ brand, uppercase }} - XXXX-XXXX-XXXX-{{ last4 }}', {
            brand: activePaymentMethod.brand,
            last4: activePaymentMethod.last4,
          })}
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>{t('creditCard.expiration.label', 'Expires')}</Label>
        </Column>
        <Column>
          {t('creditCard.expiration.value', '{{ expMonth }} / {{ expYear }}', {
            expMonth: activePaymentMethod.expMonth,
            expYear: activePaymentMethod.expYear,
          })}
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>{t('creditCard.actions.label', 'Card actions')}</Label>
        </Column>
        <Column>
          <Actions>
            <Button onClick={openUpdateModal} bordered variant={BUTTON_VARIANT.PRIMARY}>
              {t('creditCard.actions.updateCard', 'Update card')}
            </Button>
            <Tooltip
              content={t(
                'creditCard.actions.removeCard.tooltip',
                'To remove credit card you have to downgrade your current subscription {{ plan, uppercase }} {{ period, uppercase }} to FREE plan',
                { plan: subscription.planType, period: subscription.planPeriod },
              )}
            >
              <RemoveCardButtonWrapper>
                <Button
                  bordered
                  variant={BUTTON_VARIANT.PRIMARY}
                  disabled={!subscription.isFreePlan}
                  wrappedByTooltip
                >
                  {t('creditCard.actions.removeCard.button', 'Remove card')}
                </Button>
              </RemoveCardButtonWrapper>
            </Tooltip>
          </Actions>
        </Column>
      </Row>
    </Container>
  );
};

CreditCard.defaultProps = {
  className: null,
};

CreditCard.propTypes = {
  className: PropTypes.string,
};

export default CreditCard;
