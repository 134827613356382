import { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import SelectComponent from '@bugbug/core/components/Select';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

import {
  Description as DescriptionComponent,
  Label as LabelComponent,
} from '~/components/FormField/FormField.styled';
import Page from '~/components/Page';
import { TabContent as TabContentComponent } from '~/components/Tabs';

export const Container = styled(Page)`
  display: block;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
`;

export const TabContent = styled(TabContentComponent)`
  padding-top: 26px;
  padding-bottom: 74px;
`;

export const SettingsDescriptionWrapper = styled.div`
  width: 100%;
  min-height: 60px;
  padding: 3px 0;
  display: flex;
  align-items: center;
`;

export const SettingsDescription = styled.p`
  color: ${COLOR.GRAY_19};
  line-height: 18px;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 54px;
  padding: 6px 0 9px;
  background-color: ${COLOR.WHITE};
`;

export const Column = styled.div`
  min-height: 38px;

  &:nth-of-type(1) {
    width: 170px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
  }

  &:nth-of-type(2) {
    width: 360px;
    margin-right: 8px;
    display: flex;
    align-items: center;

    > * {
      width: 100%;
    }
  }

  &:last-of-type {
    margin: 0;
  }
`;

export const HelpIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  margin-left: 5px;
  transform: translateY(-1px);
`;

export const Label = styled(LabelComponent)`
  padding-bottom: 1px;
  width: fit-content;
`;

export const Description = styled(DescriptionComponent)`
  padding: 0;
`;

export const SubmitButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  type: 'submit',
}))`
  min-width: 110px;
  margin: 25px 0 12px;

  div {
    justify-content: center;
  }
`;

export const Select = styled(SelectComponent)`
  span,
  div {
    color: ${COLOR.DARK_GRAY};
  }
`;

export const Form = styled.form`
  position: relative;
`;
