import Link from '@bugbug/core/components/Link';
import { Trans } from 'react-i18next';

import { SUBSCRIPTION_PERIOD, SUBSCRIPTION_TYPE } from '~/constants/subscription';
import i18n from '~/translations';

export const SUBSCRIPTION_PERIOD_DETAILS = {
  [SUBSCRIPTION_PERIOD.YEARLY]: {
    name: i18n.t('accountSettings.subscription.period.yearly.label', 'Annually'),
    discount: i18n.t('accountSettings.subscription.period.yearly.discount', '0.15'),
  },
  [SUBSCRIPTION_PERIOD.MONTHLY]: {
    name: i18n.t('accountSettings.subscription.period.monthly.label', 'Monthly'),
    discount: i18n.t('accountSettings.subscription.period.monthly.discount', '0'),
  },
};

export const SUBSCRIPTION_DETAILS = {
  [SUBSCRIPTION_TYPE.FREE]: {
    name: i18n.t('accountSettings.subscription.plan.free.name', 'Free'),
    description: i18n.t(
      'accountSettings.subscription.plan.free.description',
      'For testing simple web apps in your own browser',
    ),
    features: [
      { label: i18n.t('accountSettings.subscription.features.tests.label', 'Unlimited tests') },
      {
        label: i18n.t(
          'accountSettings.subscription.features.localRuns.label',
          'Unlimited local tests runs',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.features.localRuns.tooltip',
          'Run tests in you own browser without limits.',
        ),
      },
      { label: i18n.t('accountSettings.subscription.features.suites.label', 'Unlimited suites') },
      {
        label: i18n.t('accountSettings.subscription.features.users.label', 'Unlimited users'),
        tooltip: i18n.t(
          'accountSettings.subscription.features.users.tooltip',
          'Collaborate with all your team members for free.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.features.components.label', 'Components'),
        tooltip: i18n.t(
          'accountSettings.subscription.features.components.tooltip',
          'Components are groups of steps that are shared across multiple tests. Use components to easily edit multiple tests at once.',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.features.conditions.label',
          'Smart waiting conditions',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.features.conditions.tooltip',
          'Before running each test step, BugBug will do a series of checks if your page is ready for a specific action.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.features.screenshots.label', 'Screenshots'),
        tooltip: i18n.t(
          'accountSettings.subscription.features.screenshots.tooltip',
          'BugBug takes a full-size screenshot for every action.',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.plan.free.features.retention.label',
          '7 days of tests history',
        ),
      },
    ],
  },
  [SUBSCRIPTION_TYPE.PRO]: {
    name: i18n.t('accountSettings.subscription.plan.pro.name', 'Pro'),
    description: i18n.t(
      'accountSettings.subscription.plan.pro.description',
      'For professional testing & monitoring web apps or e-commerce',
    ),
    features: [
      {
        label: i18n.t(
          'accountSettings.subscription.plan.pro.features.label',
          'Everything in FREE plus:',
        ),
        important: true,
      },
      {
        label: i18n.t(
          'accountSettings.subscription.features.cloudRuns.label',
          'Unlimited cloud runs',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.features.cloudRuns.tooltip',
          'Run tests via BugBug cloud virtual machine. You can run tests unlimited number of times.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.features.schedule.label', 'Schedule'),
        tooltip: i18n.t(
          'accountSettings.subscription.features.schedule.tooltip',
          'Monitor your website and stay confident it works as it should.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.features.email.label', 'E-mail notifications'),
        tooltip: i18n.t(
          'accountSettings.subscription.features.email.tooltip',
          'Get notifications when tests on schedule failed.',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.plan.pro.features.slackNotifications.label',
          'Slack notifications',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.plan.pro.features.slackNotifications.tooltip',
          'Get real-time, detailed updates on test runs, suite executions, or scheduled runs in your Slack messages.',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.features.integrations.label',
          'CI/CD integrations',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.features.integrations.tooltip',
          'Integrate with GitHub, GitLab, Bitbucket Pipelines, etc.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.features.api.label', 'API Access'),
        tooltip: i18n.t(
          'accountSettings.subscription.features.api.tooltip',
          'Run tests via BugBug command line interface.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.features.variables.label', 'Variables'),
        tooltip: i18n.t(
          'accountSettings.subscription.features.variables.tooltip',
          'Handle advanced testing scenarios, work on multiple environments, generate unique user emails, etc.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.plan.pro.features.profiles.label', 'Profiles'),
        tooltip: i18n.t(
          'accountSettings.subscription.plan.pro.features.profiles.tooltip',
          'Use your own presets for different variable values to run tests or suites on different environments, or to override your default variables to a specific value.',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.plan.pro.features.retention.label',
          '1 month of tests history',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.plan.pro.features.customJS.label',
          'Custom JavaScript steps',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.plan.pro.features.customJS.tooltip',
          'Perform any function during your tests with the power and flexibility of custom code actions.',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.plan.pro.features.inbox.label',
          'Inbox for e-mail testing',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.plan.pro.features.inbox.tooltip',
          'Use the BugBug inbox feature for user registration and login tests. Generate a unique email address, receive the email with the verification link.',
        ),
      },
      {
        label: i18n.t('accountSettings.subscription.plan.pro.features.webhooks.label', 'Webhooks'),
        tooltip: i18n.t(
          'accountSettings.subscription.plan.pro.features.webhooks.tooltip',
          "Integrate BugBug into your current workflow, for example by sending notifications to your team's communicator, such as Slack.",
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.plan.pro.features.pdfReportsForSuiteRuns.label',
          'PDF reports for suite runs',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.plan.pro.features.pdfReportsForSuiteRuns.tooltip',
          'Download a PDF report for each suite run in your project.',
        ),
      },
    ],
  },
  [SUBSCRIPTION_TYPE.PARALLEL]: {
    name: i18n.t('accountSettings.subscription.plan.parallel.name', 'Parallel'),
    description: i18n.t(
      'accountSettings.subscription.plan.parallel.description',
      'For blazing fast testing & monitoring in larger teams',
    ),
    features: [
      {
        label: i18n.t(
          'accountSettings.subscription.plan.parallel.features.label',
          'Everything in PRO plus:',
        ),
        important: true,
      },
      {
        label: (
          <Trans i18nKey="accountSettings.subscription.features.parallel.label">
            Up to 32x faster testing via parallel tests execution in the cloud <br />
            <Link to={i18n.t('default.docs.parallel')}>
              +$80 monthly per extra parallel capability
            </Link>
          </Trans>
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.plan.parallel.features.retention.label',
          '3 months of tests history',
        ),
      },
      {
        label: i18n.t(
          'accountSettings.subscription.features.support.label',
          'Premium customer support',
        ),
        tooltip: i18n.t(
          'accountSettings.subscription.features.support.tooltip',
          'You will get exclusive time with our testing experts who will help you create basic tests, debug failures and set-up your integrations.',
        ),
      },
    ],
  },
};
