import i18n from '~/translations';

export const ORG_SETTINGS_TAB = {
  GENERAL: 'general',
  USERS: 'users',
  BILLING_ADDRESS: 'billing-address',
  SUBSCRIPTION: 'subscription',
  CREDIT_CARD: 'credit-card',
  INVOICES: 'invoices',
};

export const ORG_SETTINGS_TAB_LABEL = {
  [ORG_SETTINGS_TAB.GENERAL]: i18n.t('organizationSettings.tabs.general.label', 'General'),
  [ORG_SETTINGS_TAB.USERS]: i18n.t('organizationSettings.tabs.users.label', 'Users'),
  [ORG_SETTINGS_TAB.BILLING_ADDRESS]: i18n.t(
    'organizationSettings.tabs.billingAddress.label',
    'Billing address',
  ),
  [ORG_SETTINGS_TAB.SUBSCRIPTION]: i18n.t(
    'organizationSettings.tabs.subscription.label',
    'Subscription',
  ),
  [ORG_SETTINGS_TAB.CREDIT_CARD]: i18n.t(
    'organizationSettings.tabs.creditCard.label',
    'Credit card',
  ),
  [ORG_SETTINGS_TAB.INVOICES]: i18n.t('organizationSettings.tabs.invoices.label', 'Invoices'),
};
