import ButtonBase, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 40px 30px;
  border: 1px solid #808080;
  border-radius: 10px;
  background-color: ${COLOR.WHITE};
  box-shadow: 0 7px 50px -3px rgba(47, 46, 55, 0.08), 0 1px 2px 0 rgba(30, 32, 59, 0.11);
`;

export const Name = styled.h3`
  padding-bottom: 11px;
  color: ${COLOR.PRIMARY_LIGHT};
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-weight: ${FONT_WEIGHT.NORMAL};
`;

export const HelperIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  margin-left: 6px;
  width: 14px;
  bottom: -1px;
  position: relative;
`;

export const Price = styled.span`
  color: ${COLOR.GRAY_13};
  font-size: 18px;
  text-align: center;
  margin-bottom: 22px;

  p {
    font-size: 36px;
    line-height: 32px;
    color: ${COLOR.DARK_GRAY};
    font-weight: ${FONT_WEIGHT.NORMAL};
    display: inline;
  }
`;

export const Description = styled.p`
  max-width: 90%;
  min-height: 40px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Features = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  flex: 1;
`;

export const Feature = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  flex-wrap: wrap;

  ${styleWhenTrue(
    prop('important'),
    css`
      font-weight: ${FONT_WEIGHT.BOLD};
    `,
  )}

  &[disabled] {
    opacity: 0.28;
  }
`;

export const Button = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 29px;

  > div {
    justify-content: center;
  }
`;

export const CurrentPlan = styled.div`
  display: flex;
  min-height: 38px;
  padding-right: 9px;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 29px;
  align-items: center;
  border-radius: 20px;
  background-color: ${COLOR.PRIMARY_3};
  color: ${COLOR.PRIMARY_BORDER};
  width: 100%;
  padding-bottom: 2px;

  svg {
    width: 20px;
    margin-bottom: -2px;
    margin-right: 5px;
  }
`;

export const ExtraMessage = styled.div`
  color: ${COLOR.GRAY_12};
  line-height: 16px;
  width: 100%;

  & + ${Button} {
    margin-top: 19px;
  }
`;

export const CancelButton = styled(ButtonBase)`
  padding: 0;
  height: auto;
  align-self: flex-start;

  > * {
    margin: 0;
  }
`;
