import styled from 'styled-components';

import FormFieldBase from '~/components/FormField';
import { Footer as FooterBase } from '~/components/modals/Modal';

export const Container = styled.div``;

export const FormField = styled(FormFieldBase)`
  padding-bottom: 0;
`;

export const Footer = styled(FooterBase)`
  position: relative;

  svg {
    position: absolute;
    left: 0;
  }
`;
