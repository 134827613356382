import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React from 'react';

import config from '~/modules/config';

const stripePromise = loadStripe(config.STRIPE_KEY);

const StripeElements = ({ children }) => <Elements stripe={stripePromise}>{children}</Elements>;

StripeElements.defaultProps = {};

StripeElements.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StripeElements;
