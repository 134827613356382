import i18n from '~/translations';

export const CHECKOUT_STEP = {
  BILLING_ADDRESS: {
    id: 1,
    label: i18n.t('checkoutWizard.billingAddress.label', 'Billing address'),
  },
  PAYMENT: {
    id: 2,
    label: i18n.t('checkoutWizard.payment.label', 'Payment'),
  },
  CONFIRMATION: {
    id: 3,
    label: i18n.t('checkoutWizard.confirmation.label', 'Confirmation'),
  },
};

export const CHECKOUT_STEPS_LIST = [
  CHECKOUT_STEP.BILLING_ADDRESS,
  CHECKOUT_STEP.PAYMENT,
  CHECKOUT_STEP.CONFIRMATION,
];
