import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import {
  Step as MuiStepBase,
  StepLabel as MuiStepLabelBase,
  Stepper as MuiStepperBase,
} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Description = styled.p`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  width: 100%;
  margin-bottom: 13px;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  position: relative;

  button {
    width: 128px;
  }

  button > div {
    flex: none;
  }

  button + button {
    margin-left: 9px;
  }

  svg {
    position: absolute;
    left: 0;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;

  > * {
    width: 100%;
  }
`;

export const Stepper = styled(MuiStepperBase)`
  && {
    padding-top: 14px;
    padding-bottom: 26px;
    height: 97px;
    max-width: 780px;
    width: 100%;

    .MuiStepConnector-line {
      margin: 0px 5px;
      border-top-width: 2px;
      border-color: ${COLOR.GRAY_9};
    }
  }
`;

export const Step = styled(MuiStepBase)``;

export const StepLabel = styled(MuiStepLabelBase)`
  && {
    .MuiStepLabel-label {
      color: ${COLOR.GRAY_13};
      font-size: 15px;
      line-height: 18px;
      font-weight: ${FONT_WEIGHT.SEMIBOLD};
      text-transform: uppercase;
      transition: color 200ms ease-in-out;
    }

    .MuiStepLabel-active {
      color: ${COLOR.DARK_GRAY};
      font-weight: ${FONT_WEIGHT.SEMIBOLD};
    }

    .MuiStepIcon {
      transition: color 200ms ease-in-out;
      color: #a3adb8;
      font-weight: ${FONT_WEIGHT.SEMIBOLD};
    }

    .MuiStepIcon-active,
    .MuiStepIcon-completed {
      color: ${COLOR.PRIMARY_LIGHT};
    }

    .MuiStepIcon-text {
      font-weight: ${FONT_WEIGHT.SEMIBOLD};
    }
  }
`;
