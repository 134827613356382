import { COLOR } from '@bugbug/core/theme/colors';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 98px;

  svg {
    width: 64px;
    height: 64px;
    color: ${COLOR.SUCCESS};

    ${styleWhenTrue(
      prop('error'),
      css`
        color: ${COLOR.ERROR};
      `,
    )}
  }

  button {
    min-width: 128px;
    margin-top: 38px;

    > div {
      justify-content: center;
    }
  }
`;

export const Title = styled.p`
  margin-top: 29px;
  font-size: 18px;
  margin-bottom: 0;
`;

export const Description = styled.p`
  margin-top: 7px;
  margin-bottom: 0;
`;

export const ErrorMessage = styled.span`
  margin-bottom: 0;
  text-align: center;
`;
