import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Footer } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import {
  selectSubscription,
  selectScheduledSubscription,
} from '~/modules/organization/organization.selectors';

import { Container, Content } from './SubscriptionCancelModal.styled';

const SubscriptionCancelModal = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const currentSubscription = useSelector(selectSubscription);
  const scheduledSubscription = useSelector(selectScheduledSubscription);

  const { planType: currentPlan, planPeriod: currentPeriod } = currentSubscription;
  const { planType: scheduledPlan, planPeriod: scheduledPeriod } = scheduledSubscription || {};

  const cancelRequestState = useActionState(OrganizationActions.cancelSubscriptionRequest, {
    onSuccess: modal.hide,
  });

  const handleConfirmCancellation = useCallback(() => {
    dispatch(OrganizationActions.cancelSubscriptionRequest());
  }, [dispatch]);

  return (
    <Container className={className} data-testid="SubscriptionCancelModal">
      <Content>
        <Icon name="help" />
        <div>
          <Header>
            {t('subscriptionCancelModal.title', 'Abort the upcoming change of subscription?')}
          </Header>
          <p>
            {t(
              'subscriptionCancelModal.description',
              'Are you sure you want to cancel the upcoming change to {{ scheduledPlan, uppercase}} {{ scheduledPeriod }} plan? You will keep the current {{ currentPlan, uppercase}} {{ currentPeriod }} plan.',
              { currentPlan, currentPeriod, scheduledPlan, scheduledPeriod },
            )}
          </p>
        </div>
      </Content>
      <Footer>
        <Button onClick={modal.hide}>{t('subscriptionCancelModal.declineButton', 'No')}</Button>
        <ActionButton
          onClick={handleConfirmCancellation}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={cancelRequestState.isLoading}
        >
          {t('subscriptionCancelModal.confirmButton', 'Yes')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

SubscriptionCancelModal.defaultProps = {
  className: null,
};

SubscriptionCancelModal.propTypes = {
  className: PropTypes.string,
};

export default SubscriptionCancelModal;
