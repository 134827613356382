import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../OrganizationSettings.styled';
import BillingAddressForm from '../BillingAddressForm';

const BillingAddress = () => {
  const { t } = useTranslation();

  const renderSubmit = useCallback(
    ({ submitProps }) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <SubmitButton {...submitProps}>{t('default.button.save')}</SubmitButton>
    ),
    [t],
  );

  return <BillingAddressForm renderSubmit={renderSubmit} />;
};

export default BillingAddress;
