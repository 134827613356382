import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0 8px 0 16px;
  justify-content: space-between;
  background-repeat: no-repeat;
  box-shadow: 0 7px 50px -3px rgba(47, 46, 55, 0.08), 0 1px 2px 0 rgba(30, 32, 59, 0.11);
  border-radius: 10px;
  background-color: ${COLOR.WHITE};
  height: 50px;
  align-items: center;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-bottom: 40px;
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
`;

export const Type = styled.div`
  padding-right: 4px;
  color: #a2a1a1;
  font-size: 12px;
  line-height: 18px;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-transform: uppercase;
`;

export const Value = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;
