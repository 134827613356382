import { useTranslation } from 'react-i18next';

import type { OrganizationSubscriptionSchedule } from '@bugbug/core/types/organizations';
import type { PlanPeriod } from '@bugbug/core/types/subscriptions';

import * as S from './PaymentSummary.styled';

const PaymentSummary = ({
  planPeriod,
  priceWithTax,
  taxPercent,
}: Pick<OrganizationSubscriptionSchedule, 'planPeriod' | 'priceWithTax' | 'taxPercent'>) => {
  const { t } = useTranslation();

  const formatVAT = (vat: string): string => parseInt(parseFloat(vat).toFixed(0), 10).toString();

  const periodLabels: Record<PlanPeriod, string> = {
    annually: t('accountSettings.paymentSummary.periodName.annually', '12 months'),
    monthly: t('accountSettings.paymentSummary.periodName.monthly', '1 month'),
    yearly: t('accountSettings.paymentSummary.periodName.yearly', '12 months'),
  };

  return (
    <S.Container>
      <S.Row>
        <S.Label>{t('accountSettings.paymentSummary.periodLabel', 'Billing period')}</S.Label>
        <S.Period>{periodLabels[planPeriod]}</S.Period>
      </S.Row>
      <S.Row>
        <S.Label>{t('accountSettings.paymentSummary.billedAmountLabel', 'Billing today')}</S.Label>
        <S.Amount>${priceWithTax}</S.Amount>
      </S.Row>

      <S.VATNote>
        {t('accountSettings.paymentSummary.vatRate', 'Includes VAT ({{vatRate}}%)', {
          vatRate: formatVAT(taxPercent),
        })}
      </S.VATNote>
    </S.Container>
  );
};

export default PaymentSummary;
