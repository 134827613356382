import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Header, Content } from '~/components/modals/Modal';
import StripeElements from '~/components/StripeElements';
import useModal from '~/hooks/useModal';
import { selectSubscription } from '~/modules/organization/organization.selectors';

import PaymentForm from '../PaymentForm';

import { Container, FormField, Footer } from './UpdateCardModal.styled';

const UpdateCardModal = ({ className }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const subscription = useSelector(selectSubscription);

  const renderFooter = useCallback(
    ({ submitProps, stripeElement }) => (
      <Footer>
        {stripeElement}
        <Button onClick={modal.hide}>{t('default.button.cancel')}</Button>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ActionButton type="submit" variant={BUTTON_VARIANT.PRIMARY} {...submitProps}>
          {t('default.button.save')}
        </ActionButton>
      </Footer>
    ),
    [t, modal.hide],
  );

  return (
    <Container className={className} data-testid="UpdateCardModal">
      <Header>{t('updateCardModal.title', 'Update card')}</Header>
      <Content>
        <FormField label={t('updateCardModal.cardFieldLabel', 'Enter new card')}>
          <StripeElements>
            <PaymentForm
              planType={subscription.planType}
              planPeriod={subscription.planPeriod}
              onSubmit={modal.hide}
              renderSubmit={renderFooter}
              forceAdd
              updateDefaultCard
            />
          </StripeElements>
        </FormField>
      </Content>
    </Container>
  );
};

UpdateCardModal.defaultProps = {
  className: null,
};

UpdateCardModal.propTypes = {
  className: PropTypes.string,
};

export default UpdateCardModal;
