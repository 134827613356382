import EmptyStateBase from '@bugbug/core/components/EmptyState';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.ul`
  padding: 0;
  margin: 0;
  max-width: 600px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const ListItem = styled.li`
  display: flex;
  min-height: 40px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${COLOR.GRAY_9};
`;

export const EmptyState = styled(EmptyStateBase)`
  position: relative;
`;
