import EmptyStateBase from '@bugbug/core/components/EmptyState';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -12px;
`;

export const Actions = styled.div`
  display: flex;
`;

export const EmptyState = styled(EmptyStateBase)`
  max-width: 590px;
  position: relative;
`;

export const RemoveCardButtonWrapper = styled.div`
  margin-left: 9px;
`;
