import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import { is } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import urls, { reverse } from '~/views/urls';

import { Container, Title, Description, ErrorMessage } from './PaymentStatus.styled';

const PaymentStatus = ({ error, plan }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { organizationId } = useParams();

  const handleBackClick = () => {
    history.goBack();
  };

  const handleDoneClick = () => {
    history.push(reverse(urls.subscription, { organizationId }));
  };

  if (error) {
    return (
      <Container error={!!error} data-testid="PaymentStatus">
        <Icon name="error" />
        <Title>{t('paymentStatus.failure.title', 'Payment failed')}</Title>
        <Description>
          {t('paymentStatus.failure.description', 'Your payment was declined.')}
          {is(String, error) && (
            <ErrorMessage>
              {t('paymentStatus.failure.errorMessage', 'Message: "{{ error }}"', { error })}
            </ErrorMessage>
          )}
        </Description>
        <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleBackClick}>
          {t('paymentStatus.failure.button', 'Go back')}
        </Button>
      </Container>
    );
  }

  return (
    <Container data-testid="PaymentStatus">
      <Icon name="checkCircle" />
      <Title>{t('paymentStatus.success.title', 'Thank you!')}</Title>
      <Description>
        {t('paymentStatus.success.description', "You've succesfully activated a {{ plan }} plan.", {
          plan,
        })}
      </Description>
      <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleDoneClick}>
        {t('paymentStatus.success.button', 'Ok, done')}
      </Button>
    </Container>
  );
};

PaymentStatus.defaultProps = {
  error: false,
};

PaymentStatus.propTypes = {
  plan: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default PaymentStatus;
