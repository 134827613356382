import i18n from '~/translations';

export const STRIPE_ERROR_MESSAGE = {
  invalid_number: i18n.t(
    'stripeError.invalidNumber',
    'The card number is not a valid credit card number',
  ),
  invalid_expiry_month: i18n.t(
    'stripeError.invalidExpiryMonth',
    'The card’s expiration month is invalid',
  ),
  invalid_expiry_year: i18n.t(
    'stripeError.invalidExpiryYear',
    'The card’s expiration year is invalid',
  ),
  invalid_cvc: i18n.t('stripeError.invalidCvc', 'The card’s security code is invalid'),
  incorrect_number: i18n.t('stripeError.incorrectNumber', 'The card number is incorrect'),
  incomplete_number: i18n.t('stripeError.incompleteNumber', 'The card number is incomplete'),
  incomplete_cvc: i18n.t('stripeError.incompleteCvc', 'The card’s security code is incomplete'),
  incomplete_expiry: i18n.t(
    'stripeError.incompleteExpiry',
    'The card’s expiration date is incomplete',
  ),
  expired_card: i18n.t('stripeError.expiredCard', 'The card has expired'),
  incorrect_cvc: i18n.t('stripeError.incorrectCvc', 'The card’s security code is incorrect'),
  incorrect_zip: i18n.t('stripeError.incorrectZip', 'The card’s zip code failed validation'),
  invalid_expiry_year_past: i18n.t(
    'invalidExpiryYearPast',
    'The card’s expiration year is in the past',
  ),
  card_declined: i18n.t('stripeError.cardDeclined', 'The card was declined'),
  missing: i18n.t('stripeError.missing', 'There is no card on a customer that is being charged'),
  processing_error: i18n.t(
    'stripeError.processingError',
    'An error occurred while processing the card',
  ),
};
